import { useState } from 'react'

import FormItem from '../../FormItem'
import SelectField from '../../SelectField'
import { ProductInterface } from '../../../interfaces'
import { ProductDetailInterface } from '../../../hooks'
import { formatToSentenceCase, productAttributeFormat, reorderAttributes, splitByPascalCase } from '../../../helpers'

export function ImageRequirements({
  productDetails,
  skuSearchResult
}: {
  productDetails: ProductDetailInterface
  skuSearchResult: ProductInterface
}) {
  const selectableAttributeEntries = Object.entries(productDetails.attributes).filter(
    ([, attributeOptions]) => attributeOptions.length > 1
  )

  const initialSelectedAttributes = selectableAttributeEntries.reduce(
    (previousValue, [attributeName, attributeOptions]) => {
      const firstAvailableAttributeOption = attributeOptions[0]
      return {
        ...previousValue,
        [attributeName]: firstAvailableAttributeOption
      }
    },
    {}
  )

  const printAreaEntries = Object.entries(productDetails.printAreas)
  const [initialSelectedPrintArea] = printAreaEntries[0]

  return (
    <>
      <h2 className="mt-0 text-lg md:mt-8 lg:mt-0 lg:font-normal">Image requirements</h2>

      <div className="flex flex-col gap-2">
        <ImageRequirementsSelector
          initialSelectedAttributes={initialSelectedAttributes}
          initialSelectedPrintArea={initialSelectedPrintArea}
          printAreaEntries={printAreaEntries}
          productDetails={productDetails}
          selectableAttributeEntries={selectableAttributeEntries}
          skuSearchResult={skuSearchResult}
        />
      </div>
    </>
  )
}

function ImageRequirementsSelector({
  initialSelectedAttributes,
  initialSelectedPrintArea,
  printAreaEntries,
  productDetails,
  selectableAttributeEntries,
  skuSearchResult
}: {
  initialSelectedAttributes: Record<string, string>
  initialSelectedPrintArea: string
  printAreaEntries: Array<[string, { required: boolean }]>
  productDetails: ProductDetailInterface
  selectableAttributeEntries: Array<[string, string[]]>
  skuSearchResult: ProductInterface
}) {
  const [selectedAttributes, setSelectedAttributes] = useState(initialSelectedAttributes)
  const [selectedPrintArea, setSelectedPrintArea] = useState(initialSelectedPrintArea)

  const showPrintAreaSelector = printAreaEntries.length > 1
  const hasSelectableAttributes = selectableAttributeEntries.length > 0

  return (
    <>
      {showPrintAreaSelector && (
        <div className="flex">
          <FormItem
            className="max-w-lg"
            inputField={
              <SelectField
                onChange={(event) => {
                  setSelectedPrintArea(event.target.value)
                }}
              >
                {printAreaEntries.map(([printAreaName]) => (
                  <option key={printAreaName} value={printAreaName}>
                    {formatToSentenceCase(printAreaName)}
                  </option>
                ))}
              </SelectField>
            }
            labelText="Print Area"
            labelClassName="mt-0"
          />
        </div>
      )}

      {hasSelectableAttributes && (
        <div className={`flex flex-wrap gap-x-6 ${showPrintAreaSelector ? 'mt-4' : ''}`}>
          {selectableAttributeEntries.map(([attributeName, attributeOptions]) => (
            <FormItem
              className="max-w-lg"
              inputField={
                <SelectField
                  className="w-auto"
                  onChange={(event) => {
                    setSelectedAttributes({ ...selectedAttributes, [attributeName]: event.target.value })
                  }}
                >
                  {reorderAttributes(attributeName, attributeOptions, skuSearchResult.category).map(
                    (attributeOption) => (
                      <option key={attributeOption} value={attributeOption}>
                        {productAttributeFormat(attributeOption)}
                      </option>
                    )
                  )}
                </SelectField>
              }
              key={attributeName}
              labelText={formatToSentenceCase(splitByPascalCase(attributeName))}
              labelClassName="mt-0 mb-4"
            />
          ))}
        </div>
      )}

      <div className={`flex flex-col gap-2 ${showPrintAreaSelector || hasSelectableAttributes ? 'mt-4' : ''}`}>
        <ImageRequirementsInfo
          printAreaEntries={printAreaEntries}
          productDetails={productDetails}
          selectedAttributes={selectedAttributes}
          selectedPrintArea={selectedPrintArea}
          showPrintAreaInfo={showPrintAreaSelector}
          skuSearchResult={skuSearchResult}
        />
      </div>
    </>
  )
}

function ImageRequirementsInfo({
  printAreaEntries,
  productDetails,
  selectedAttributes,
  selectedPrintArea,
  showPrintAreaInfo,
  skuSearchResult
}: {
  printAreaEntries: Array<[string, { required: boolean }]>
  productDetails: ProductDetailInterface
  selectedAttributes: Record<string, string>
  selectedPrintArea: string
  showPrintAreaInfo: boolean
  skuSearchResult: ProductInterface
}) {
  const selectedAttributeEntries = Object.entries(selectedAttributes)
  const productVariantDataForSelectedAttributes = productDetails.variants.find((variant) => {
    return selectedAttributeEntries.every(
      ([selectedAttributeName, selectedAttributeValue]) =>
        variant.attributes[selectedAttributeName] === selectedAttributeValue
    )
  })
  const imageResolutionData = productVariantDataForSelectedAttributes?.printAreaSizes[selectedPrintArea]
  const selectedPrintAreaEntry = printAreaEntries.find(([printAreaName]) => printAreaName === selectedPrintArea)

  if (!imageResolutionData || !selectedPrintAreaEntry) {
    return <>Image requirements not found</>
  }

  const [, selectedPrintAreaData] = selectedPrintAreaEntry

  return (
    <>
      <span className="flex gap-3">
        <strong>Recommended dimensions:</strong>
        <span>{`${imageResolutionData.horizontalResolution}x${imageResolutionData.verticalResolution}px`}</span>
      </span>

      <span className="flex gap-3">
        <strong>DPI:</strong>
        <span>{skuSearchResult.optimumDpi}dpi</span>
      </span>

      {showPrintAreaInfo && (
        <span className="flex gap-3">
          <strong>Required?</strong>
          <span>{selectedPrintAreaData.required ? 'Yes' : 'No'}</span>
        </span>
      )}
    </>
  )
}
